<template>
        
       <div>
         <Signup @setupBtn="signupBtn" :title="$t('register.title')" signuptext="Sign Up" >
                    <div slot='content' >
                    <!-- <Uploadimg @getImgUrl='getImgUrl' /> -->
                    <input v-model="form.email"  class='input-item' type='text' :placeholder="$t('register.email')" />
                    <input v-model="form.brand_name" class='input-item' type='text' :placeholder="$t('register.brand_name')" />
                    <input v-model="form.password" class='input-item' type='password' :placeholder="$t('register.password')" />
                  </div>
                  <div slot="tolinkclick" @click="toLoginBtn" class="signup">{{$t('register.item_1')}}<span>{{$t('register.item_2')}}</span></div>
                </Signup>
         </div>
</template>

<script>

import Signup from '@/components/content/LoginSignup'
import Uploadimg from '@/components/content/UploadImgRegister'
import {register} from '@/network/common'
import Cookies from 'js-cookie';

export default {
    name: 'NodeprojectRegister',
    components: { Signup,Uploadimg },
    directives: {  },
    data() {
        return {
             loading: false,
             form:{logo:'',email:'',brand_name:'',password:''}
        };
    },
    mounted() {
        
    },
    methods: {
        toLoginBtn(){
            this.$router.replace('/login')
        },
        getImgUrl(e){
          this.form.logo=e
        },
        signupBtn(){
          //注册按钮
          let regiobj=this.form
          // if(regiobj.logo==''){
          //   alert('Please upload the logo');
          //   return false;
          // }
          if(regiobj.email==''){
            alert('Email cannot be empty');
            return false;
          }
          if(regiobj.brand_name==''){
            alert('BrandName cannot be empty');
            return false;
          }
          if(regiobj.password==''){
            alert('Password cannot be empty');
            return false;
          }
          if(regiobj.password.length < 6){
              alert('The password must have at least 6 digits');
              return false
          }
          // var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
          var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
          var isok= reg.test(regiobj.email);
          if(isok){
              // this.$message.error('邮箱格式正确！')
          }else{
              this.$message.error('Email format error!');
              return false;
          }
          register(regiobj)
          .then(res=>{
            console.log(res);
             if(res.header.code==200){
               Cookies.set("maker_id",res.body.maker_id);
               this.$message.success('success');
                // this.$router.push('/login');
                this.$router.push('/setInfo');
              // res.body.maker_id
               return ;
             }
             this.$message.info(res.header.msg);
          })
          .catch(e=>{
            console.log(e)
          })

        },
     
    },
};
</script>

<style scoped>
.login{background-image: linear-gradient(to right,rgb(200,29,21),rgb(200,29,21));height: 100vh;width: 100vw;display:flex;justify-content: center;align-items:center;}
.login .login_center{padding:50px 20px; width: 300px;border-radius: 15px;background-color: #fff;}
.login .title{font-weight:600;text-align:center;line-height: 20px;padding-bottom:40px;font-size:16px;}
.login .input-item{ display: block;width: 100%;margin-bottom: 20px;border: none;padding: 10px;border-bottom: 2px solid rgb(128,125,125);font-size: 15px;outline: none;}
.login .wrapbtn{display:flex;}
.login .wrapbtn .loginbtn{width:100%; justify-content: center;padding: 5px;margin-top: 20px; background-image: linear-gradient(to right,#fbc2eb,#a6c1ee);color: #fff;}
.login .signup{text-align: right;margin-top: 20px;cursor: pointer;}

</style>